body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.grid {
  display: grid;
}

.w15h15 {
  width: 15px;
  height: 15px;
}

.flex {
  display: flex;
}

.borderBlack {
  border: 3px solid black;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.flex4 {
  flex: 4;
}

.w100 {
  width: 100%;
}

.w75 {
  width: 75%;
}

.w50 {
  width: 50%;
}

.w25 {
  width: 25%;
}

.h100 {
  height: 100%;
}

.h75 {
  height: 75%;
}

.h50 {
  height: 50%;
}

.h25 {
  height: 25%;
}